<template>
  <div class="home-bg" :style="{backgroundImage: 'url(' + bg + ')'}">
    <el-image v-if="url" fit="none" class="banner_all" :src="url"></el-image>
    <div class="home-title">
      <el-image class="home-image" :src="require('@/assets/image/home-right.png')"></el-image>
      <div class="home-title-txt">代理产品</div>
      <el-image class="home-image" :src="require('@/assets/image/home-left.png')"></el-image>
    </div>
    <div class="hoem-title-txt2">Agency products</div>
    <div class="home-mian-all">
      <el-col v-for="(item,key) in data" :key="key" :span="8" class="home-fenji">
        <div class="home-main-content">
          <el-image class="home-image-main" :src="item.goods_image | replaceUrl"></el-image>
          <div class="home-main-txt">{{item.name}}</div>
        </div>
      </el-col>
    </div>
  </div>
</template>

<script>
import {goods,getset} from '@/api/index'
export default {
  data(){
    return{
      url:'',
      data:'',
      bg:'',
    }
  },
  created(){
    this.getHomeList()
    getset({name:'banner_image'}).then(res=>{this.url = this.$store.state.baseUrl + res.data})
    getset({name:'home-bg'}).then(res=>{this.bg = this.$store.state.baseUrl + res.data})
  },
  methods:{
    getHomeList(){
      goods().then(res=>{
        this.data = res.data
      })
    }
  }
}
</script>

<style>
  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }
  
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
</style>

<style scoped>
.banner_all{
  width: 100%;
  height: 900px;
}
.home-image{
  width: 480px;
  height: 22px;
}
.home-title{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 110px;
}
.home-title-txt{
  font-size: 48px;
  font-weight: 500;
  /* color: #333333; */
  color: #FFFFFF;
  margin: 0 25px;

}
.hoem-title-txt2{
  margin-top: 30px;
  text-align: center;
  font-size: 26px;
  color: #C1C1C1;

}
.home-mian-all{
  width: 80%;
  margin: auto;
  margin-top: 80px;
  display: flex;
  flex-wrap: wrap;
}
.home-main-content{
  margin: auto;
  margin-bottom: 95px;
  width: 395px;
  border: 1px solid #EAEAEA;
  background: #FFFFFF;
}
.home-main-content:hover{
  box-shadow: 0px 0px 35px 0px rgba(152, 152, 152, 0.18);
  transform: translateY(-4px);
}
.home-image-main{
  height: 397px;
  width: 100%;
}
.home-main-txt{
  height: 115px;
  background: #FFFFFF;
  text-align: center;
  line-height: 115px; 
  font-size: 30px;
  font-weight: 500;
  color: #333333;
}
.home-bg{
    background: url('../../assets/image/login-bg.png') repeat fixed;
    background-size: cover; 
    background-position: center center;
}
</style>